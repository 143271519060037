import React, { useContext, useEffect } from "react";
import Router from "next/router";
import { Spin } from "antd";
import { styled } from "styled-components";
import { AuthContext } from "./utils/AuthContext";

const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const { authenticated, loading, user } = useContext(AuthContext);
    useEffect(() => {
      const { pathname } = Router;
      if (!loading) {
        if (authenticated !== null && !authenticated) {
          Router.push("/login");
          return;
        }

        if (authenticated && pathname == "/") {
          Router.push("/articles");
        }
      }
    }, [authenticated, loading, user]);

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size="large" className="loader" />
        </LoaderWrapper>
      );
    }
    return <>{authenticated && <> {children} </>} </>;
  }
  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};

export default PrivateRoute;
